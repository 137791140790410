import {
  SET_CLUBS,
  SET_CLUB,
  CLEAR_CLUB,
  ADD_CLUB_CATEGORY,
  EDIT_CLUB_CATEGORY,
  REMOVE_CLUB_CATEGORY,
  SET_CLUB_CATEGORIES,
  SET_FEE_CATEGORIES,
  ADD_FEE_CATEGORY,
  CLEAR_FEE_CATEGORY,
  EDIT_FEE_CATEGORY,
  DELETE_FEE_CATEGORY,
  SET_CASH_PERMISSION,
  SET_CARD_PERMISSION,
  SET_WALLET_PERMISSION,
  SET_NEW_NOVELTIES
} from './types'
import APIClub from '@/services/APIClub.js'
import tools from '@/Tools/tools.js'
import APIService from '../services/APIService'
import APIDispensaryPro from '../services/APIDispensaryPro'
import { DialogProgrammatic as Dialog } from 'buefy'

const initialState = {
  clubs: [],
  currentClub: Object,
  categories: [],
  feeCategories: [],
  cashPermission: true,
  cardPermission: true,
  walletPermission: true,
  newNovelties: false
}
const getters = {
  getCurrentClub: state => {
    return state.currentClub
  }
}

const mutations = {
  [SET_NEW_NOVELTIES](state, newNovelties) {
    state.newNovelties = newNovelties
  },
  [SET_CASH_PERMISSION](state, cashPermission) {
    state.cashPermission = cashPermission
  },
  [SET_CARD_PERMISSION](state, cardPermission) {
    state.cardPermission = cardPermission
  },
  [SET_WALLET_PERMISSION](state, walletPermission) {
    state.walletPermission = walletPermission
  },
  [SET_CLUBS](state, clubs) {
    state.clubs = clubs
  },
  [SET_CLUB](state, club) {
    localStorage.setItem('currentClub', JSON.stringify(club))
    state.currentClub = club
  },
  [CLEAR_CLUB](state) {
    console.log('Entra en [CLEAR_CLUB]')
    state.clubs = []
    state.currentClub = Object
    localStorage.removeItem('club')
    localStorage.removeItem('currentClub')
    console.log('Club cleared')
  },
  [ADD_CLUB_CATEGORY](state, category) {
    state.categories.push(category)
  },
  [REMOVE_CLUB_CATEGORY](state, category) {
    state.categories.splice(state.categories.indexOf(category), 1)
  },
  [EDIT_CLUB_CATEGORY](state, { category, newcategory }) {
    var index = state.categories.indexOf(category)
    if (index !== -1) {
      state.categories[index].title = newcategory.title
      state.categories[index].descuento = newcategory.descuento
    }
  },
  [EDIT_FEE_CATEGORY](state, { feeCategory, json }) {
    var index = state.feeCategories.indexOf(feeCategory)
    if (index !== -1) {
      state.feeCategories[index].title = json.title
      state.feeCategories[index].price = json.price
      state.feeCategories[index].code = json.code
      state.feeCategories[index].asocid = json.asocid
      state.feeCategories[index].days = json.days
      state.feeCategories[index].status = json.status
    }
  },
  [DELETE_FEE_CATEGORY](state, feeCategory) {
    state.feeCategories.splice(state.feeCategories.indexOf(feeCategory), 1)
  },
  [SET_CLUB_CATEGORIES](state, categories) {
    state.categories = categories
  },
  [SET_FEE_CATEGORIES](state, feeCategories) {
    state.feeCategories = feeCategories
  },
  [ADD_FEE_CATEGORY](state, feeCategory) {
    state.feeCategories.push(feeCategory)
  },
  [CLEAR_FEE_CATEGORY](state) {
    state.feeCategories = []
  }
}
const actions = {
  initialize({ commit, state }) {
    if (state.currentClub != Object) {
      return
    }
    if (state.currentClub) {
      const currentClub = JSON.parse(localStorage.getItem('currentClub'))
      if (currentClub) {
        commit(SET_CLUB, currentClub)
      }
    }
  },
  fetchClubPermissions({ commit }, { asocid }) {
    let promise = APIService.getCategories(asocid, 'payment_methods').then(
      response => {
        console.log('SE BAJA LOS PERMISOS')
        console.log(response.data)
        let paymentMethods = response.data
        if (paymentMethods.length != 0) {
          commit(SET_CASH_PERMISSION, paymentMethods[0].title.includes('-cash'))
          commit(SET_CARD_PERMISSION, paymentMethods[0].title.includes('-card'))
          commit(
            SET_WALLET_PERMISSION,
            paymentMethods[0].title.includes('-wallet')
          )
        }
      }
    )
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  fetchClubs({ commit, getters }) {
    let promise = APIClub.clubs().then(response => {
      commit(SET_CLUBS, response.data)
      if (getters.getCurrentClub == Object) {
        commit(SET_CLUB, response.data[0])
      }
    })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  fetchClub({ commit }, { asocid }) {
    console.log('Entra en fetchClubs')
    let promise = APIClub.getClub(asocid)
      .then(response => {
        console.log(response.data)
        commit(SET_CLUB, response.data[0])
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  setClub({ commit }, club) {
    commit(SET_CLUB, club.club)
  },
  setNovelties({ commit }, newNovelties) {
    commit(SET_NEW_NOVELTIES, newNovelties)
  },
  updateClub({ commit, state }, { asocid, club }) {
    let promise = APIClub.updateClub(asocid, club)
      .then(response => {
        console.log(response)
        state.currentClub.body = club.body
        state.currentClub.full_html = club.full_html
        state.currentClub.html_design = club.html_design
        // commit(SET_CLUB, response.data[0])
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  clearClub({ commit }) {
    commit(CLEAR_CLUB)
  },

  fetchCategories({ commit }, { asocid, type }) {
    let promise = APIService.getCategories(asocid, type)
    // .then(response => {
    //   commit(SET_CLUB_CATEGORIES, response.data)
    // })
    // .catch(error => {
    //   tools.manageAxiosError(error)
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  createCategory({ commit }, json) {
    let promise = APIService.createCategory(json)
    // .then(response => {
    //   json.nid = response.data.nid
    //   commit(ADD_CLUB_CATEGORY, json)
    // })
    // .catch(error => {
    //   tools.manageAxiosError(error)
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  updateCategory({ commit }, { category, newcategory }) {
    let promise = APIService.updateCategory(newcategory)
    // .then(response => {
    //   commit(EDIT_CLUB_CATEGORY, { category, newcategory })
    // })
    // .catch(error => {
    //   tools.manageAxiosError(error)
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  deleteCategory({ commit }, { asocid, category }) {
    let promise = APIService.deleteCategory(asocid, category.nid)
      .then(response => {
        console.log(response)
        commit(REMOVE_CLUB_CATEGORY, category)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchFeeCategories({ commit }, { asocid }) {
    let promise = APIService.getFeeCategories(asocid)
      .then(response => {
        if (response.data) {
          commit(SET_FEE_CATEGORIES, response.data)
        } else {
          commit(CLEAR_FEE_CATEGORY)
        }

        console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  createFeeCategory({ commit }, json) {
    let promise = APIService.createFeeCategory(json)
      .then(response => {
        json.pid = response.data.pid
        commit(ADD_FEE_CATEGORY, json)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  updateFeeCategory({ commit }, { feeCategory, json }) {
    let promise = APIService.updateFeeCategory(json)
      .then(response => {
        console.log(response)
        commit(EDIT_FEE_CATEGORY, { feeCategory, json })
      })
      .catch(error => {
        console.log(error)
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  deleteFeeCategory({ commit }, { asocid, feeCategory }) {
    let promise = APIDispensaryPro.deleteProduct(asocid, [feeCategory.pid])
      .then(response => {
        if (response.data.products[0].success == false) {
          Dialog.alert({
            title: this.$t('Ha habido un problemilla'),
            message: response.data.products[0].message,
            confirmText: 'OK',
            type: 'is-danger',
            hasIcon: false,
            onConfirm: () => {
              // router.replace('/')
            }
          })
        } else {
          commit(DELETE_FEE_CATEGORY, feeCategory)
        }
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
