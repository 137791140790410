<template>
  <div id="app" class=" is-flex ">
    <!-- {{ this.$device.ios }}
    {{ this.$device.android }} -->
    <div v-if="showNavigationBar">
      <side-menu></side-menu>
    </div>
    <router-view id="page-wrap" style="padding-left:10px" class="fullwidth" />

    <NotificationBox />
    <footer v-if="shouldShowClosingWebMessage" style="background-color: #0096FF;" @click="closeClosingWebMessage()">
      <div style="margin: auto;color:white;font-weight: bold;">
        {{ closingWebMessage }}
      </div>
    </footer>
    <footer v-if="updateAvailable" style="background-color: #ffbd7a;" @click="updateVue()">
      <div style="margin: auto;color:white;font-weight: bold;">
        HAY UNA ACTUALIZACIÓN DISPONIBLE
      </div>
    </footer>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'buefy/dist/buefy.css'

// import push from '@/components/Menu/push.vue'
// import Menu from '@/components/Menu'
import SideMenu from '@/components/SideMenu.vue'
import NotificationBox from '@/components/NotificationBox.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import * as versionjson from '@/version.json'
import APIAuth from '@/services/APIAuth.js'
import { i18n } from './i18n'

export default {
  mixins: [InitViewMixin],

  data() {
    return {
      currentMenu: 'push'
    }
  },
  components: {
    // push,
    // Menu,
    NotificationBox,
    SideMenu
  },
  timers: {
    testVersion: {
      time: 300000,
      autostart: true,
      repeat: true,
      immediate: true
    }
  },
  methods: {
    testVersion() {
      this.validateVueVersion()
    },
    validateVueVersion() {
      APIAuth.serverVueVersion().then(response => {
        console.log('local version: ' + versionjson.default.version)
        console.log('server version: ' + response.data.vue_version)
        if (
          Number(versionjson.default.version) >=
          Number(response.data.vue_version)
        ) {
          this.$store.commit('SET_UPDATE_AVAILABLE', false)
        } else {
          this.$store.commit('SET_UPDATE_AVAILABLE', true)
          this.$store.dispatch('club/setNovelties', true)
        }
      })
    },
    getClosingWebMessage(language) {
      APIAuth.getClosingWebMessage(language).then(response => {
        this.$store.commit('SET_CLOSING_WEB_MESSAGE', response.data.message)
        const now = new Date()
        response.data.timestamp > now.valueOf()/1000 ? this.$store.commit('SET_SHOULD_SHOW_CLOSING_WEB_MESSAGE', true) : this.$store.commit('SET_SHOULD_SHOW_CLOSING_WEB_MESSAGE', false)
      })
    },
    closeClosingWebMessage() {
      this.$store.commit('SET_CLOSING_WEB_MESSAGE', undefined)
    },
    updateVue() {
      location.reload()
    },

    changeMenu(menu) {
      this.currentMenu = menu.replace(/ +/g, '').toLowerCase()
      return this.currentMenu
    },
    changeSide(side) {
      this.side = side
    },
  },
  computed: {
    ...mapState('orders', ['cart']),
    ...mapState(['showNavigationBar']),
    ...mapState(['sideMenuIsActive']),
    ...mapState(['updateAvailable']),
    ...mapState(['closingWebMessage']),
    ...mapState(['shouldShowClosingWebMessage'])
  },
  created() {
    window.document.title = 'Smokapp'
    setTimeout(() => {
      if (!this.$matomo) {
        this.$buefy.toast.open({
          duration: 7000,
          message: `Recuerda que usando un bloqueador de anuncios no podremos generar las estadísticas correctamente.`,
          position: 'is-bottom',
          type: 'is-success'
        })
      }
    }, 5000)
  },
  mounted() {
    const lang = JSON.parse(localStorage.getItem('language'))
    if (lang && lang.language) {
      this.$i18n.locale = lang.language
    } else {
      // this.$i18n.locale = 'es'
      this.$i18n.locale = 'es'
      // this.$i18n.locale = 'cat'
    }
    this.getClosingWebMessage(this.$i18n.locale)

  },

  watch: {
    $route(to, from) {
      //TODO lanzar esto cada cierto tiempo??
      this.validateVueVersion()
    }
  }
}
</script>
<style lang="scss">
// .sidemenu {
//   width: calc(100vw - 200px);
// }
.fullwidth {
  width: calc(100vw - 50px) !important;
}

.nomenu {
  width: 100vw;
}

::-webkit-scrollbar {
  display: none;
}

.logo img {
  width: 20px;
  height: auto;
  margin: auto;
  border-radius: 50%;
  padding: 5px;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  color: #fffce1;
  font-family: 'Raleway', Arial, sans-serif;
  margin: 0px;
}

#appmenu {
  height: 100%;
}

a {
  color: #4e4a46;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #c94e50;
  }
}

main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

h1 {
  font-weight: 800;
  font-size: 3.75em;
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;

  a {
    display: inline-block;
    margin: 0.75em;
    padding: 1.35em 1.1em;
    width: 15em;
    background: #fffce1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    border-top-left-radius: 20px 50px;
    border-top-right-radius: 20px 50px;
    border-bottom-right-radius: 20px 50px;
    border-bottom-left-radius: 20px 50px;
    cursor: pointer;

    &.currentDemo {
      background: #c94e50;
      color: #fffce1;
    }
  }
}

.sideButton {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;

  &.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active {
    background: #c94e50;
    color: #fffce1;
  }
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }

  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }

  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}

//
// Burger menu custom styles
//
.bm-burger-button {
  position: absolute;
  width: 24px;
  height: 20px;
  left: 24px;
  top: 25px;
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-morph-shape {
  fill: #ffffff;
}

.bm-menu {
  background: #373a47;

  a {
    color: #b8b7ad;

    &:hover,
    &:focus {
      color: #c94e50;
    }
  }
}

//
// Mixins
//
.menu-1 {
  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
}

.menu-2 {
  .bm-cross {
    background: #999;
  }

  .bm-menu {
    padding: 3.4em 1em 0;
  }

  a {
    padding: 1em;
  }

  i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35;
  }
}

.menu-3 {
  .bm-cross {
    background: #888;
  }

  .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em;
  }

  i {
    opacity: 0.5;
  }

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }
}

.menu-4 {
  .bm-cross {
    background: #888;
  }

  h2 {
    margin: 0 auto;
    padding: 2em 1em;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    i {
      margin-left: 0.2em;
    }

    span {
      font-size: 1.6em;
      font-weight: 700;
    }
  }

  a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    span {
      letter-spacing: 1px;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad;
    }
  }
}
</style>
<style scoped>
footer {
  padding: 2px;
  background-color: #f9f9f9;
  height: 40px;
  bottom: 0px;
  width: 100%;
  position: fixed;
  color: #717a81;
  display: flex;
}
</style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  background-color: #f9f9f9;
}

#nav a {
  font-weight: bold;
  color: #b5c8db;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style lang="scss">
@import '~bulma/sass/utilities/_all';

$navbar-height: 2.6rem;
$grey-dark: #717a81;
$green: #70c1b3;
$turquoise: #247ba0;
$yellow: #f8d133;
$red: #ed6a5a;
$orange: #eb9f3c;
$primary: #3c6a8d;
$link: $green;
$text-strong: #717a81;
$family-sans-serif: 'Open Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$family-primary: $family-sans-serif;
$colors: (
  'white': ($white,
    $black ),
  'black': ($black,
    $white ),
  'light': ($light,
    $light-invert ),
  'dark': ($dark,
    $dark-invert ),
  'primary': ($primary,
    $primary-invert ),
  'info': ($info,
    $info-invert ),
  'success': ($green,
    $success-invert ),
  'warning': ($yellow,
    $warning-invert ),
  'danger': ($red,
    $danger-invert )
);

@import '~bulma';
@import '~buefy/src/scss/buefy';</style>
