import session from '@/services/Session.js'

export default {
  fetechCashMovement(asocid) {
    return session.get('/api/cash_movement/?asocid=' + asocid.asocid)
  },
  fetechCloseRegisterhistory(asocid) {
    return session.get('/api/cash_close/?asocid=' + asocid.asocid)
  },
  fetechCloseRegisterhistoryById(asocid, cid) {
    return session.get(
      '/api/cash_close/?asocid=' + asocid.asocid + '&cid=' + cid
    )
  },
  closeRegister(json) {
    return session.post('/api/cash_close', json)
  },
  createMovement(movement) {
    return session.post('/api/cash_movement', movement)
  },
  fetechReportCash(asocid, start_date, end_date, status, cid) {
    const lang = JSON.parse(localStorage.getItem('language'))
    let selectedLanguage = ''
    if (lang && lang.language) {
      selectedLanguage = lang.language
    } else {
      selectedLanguage = 'es'
    }
    console.log('entra')
    console.log(asocid)
    console.log(start_date)
    console.log(end_date)
    if (start_date != null && end_date != null) {
      return session.get(
        '/api/report_cash/?asocid=' +
          asocid +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&status=' +
          status +
          '&lang=' +
          selectedLanguage
      )
    }
    return session.get('/api/report_cash/?asocid=' + asocid + '&cid=' + cid)
  }
}
