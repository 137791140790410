<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Método de pago') }}
      </p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>

    <footer class="modal-card-foot">
      <b-switch v-model="cash" @input="createString()">
        {{ $t('Activar efectivo') }}
      </b-switch>

      <b-switch v-model="card" @input="createString()">
        {{ $t('Activar tarjeta') }}
      </b-switch>

      <b-switch
        v-model="wallet"
        @input="createString()"
        v-if="
          currentClub.functionalities.funciones_avanzadas_gestion ||
            currentClub.functionalities.monedero
        "
      >
        {{ $t('Activar monedero') }}
      </b-switch>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  data() {
    return {
      is_editing: false,
      categoryName: null,
      categoryNid: null,
      categoryToModify: null,
      categories: [],
      wallet: undefined,
      card: undefined,
      cash: undefined
    }
  },
  computed: {
    ...mapState('club', ['currentClub'])
  },
  methods: {
    createString() {
      this.categoryName = ''
      if (this.wallet) {
        this.categoryName = '-wallet'
      }
      if (this.card) {
        this.categoryName = this.categoryName + '-card'
      }
      if (this.cash) {
        this.categoryName = this.categoryName + '-cash'
      }

      this.update()
    },
    update() {
      if (this.categories.length == 0) {
        this.sendData()
      } else {
        this.updateData()
      }
    },
    editCategory(category) {
      console.log(category)
      this.categoryToModify = category
      if (category.title) {
        this.categoryName = category.title
        if (this.categoryName.includes('-cash')) {
          this.cash = true
        }
        if (this.categoryName.includes('-card')) {
          this.card = true
        }
        if (this.categoryName.includes('-wallet')) {
          this.wallet = true
        }
      }
      this.categoryNid = category.nid
      this.is_editing = true
    },
    updateData() {
      if (!this.validateData()) {
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'payment_methods',
        title: this.categoryName,
        nid: this.categoryNid
      }
      this.$store
        .dispatch('club/updateCategory', {
          category: this.categoryToModify,
          newcategory: json
        })
        .then(() => {
          loadingComponent.close()
          this.is_editing = false
          //   this.categoryName = null

          //   this.$emit('close')
        })
    },
    sendData() {
      if (!this.validateData()) {
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'payment_methods',
        title: this.categoryName
      }
      this.$store.dispatch('club/createCategory', json).then(response => {
        loadingComponent.close()
        this.editCategory(response.data)
        this.fetchData()
      })
    },
    validateData() {
      if (this.cash || this.card || this.wallet) {
        return true
      }
      Dialog.alert({
        title: this.$t('No has seleccionado ningún método de pago'),
        message: this.$t('Debes seleccionar al menos uno'),
        confirmText: 'OK',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.cash = true
        }
      })
      return false
    },
    fetchData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('club/fetchCategories', {
          asocid: this.currentClub.nid,
          type: 'payment_methods'
        })
        .then(response => {
          loadingComponent.close()
          console.log(response)
          this.categories = response.data
          if (this.categories.length != 0) {
            //   this.categoryName = this.categories[0].title
            this.editCategory(this.categories[0])
          } else {
            this.card = true
            this.cash = true
            this.wallet = true
          }
          //   this.$emit('close')
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped></style>
